import React from 'react';
import { Globals, Breakpoints } from '../styles/global';
import { Link, graphql } from 'gatsby';
import Layout from '../layouts';
import showdown from 'showdown';
import styled from '@emotion/styled';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  OrangeBox,
  Inner,
  WhiteBox,
  RedBox,
  GreyBox,
} from '../components/shared';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import { FontSizes } from '../styles/global';
import SEO from '../components/seo';
const converter = new showdown.Converter();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: FontSizes.u1,
      fontWeight: '500',
    },
    accordionItem: {
      borderRadius: '1rem',
      marginBottom: '0.5rem',
      marginTop: '0.5rem',
      '&:first-child': {
        borderRadius: '1rem',
      },
      '&::before': {
        display: 'none',
      },
    },
    summary: {
      '& [class*="-content"]': {
        paddingLeft: '2rem',
        order: 2,
      },
      '& [class*="expandIcon"]': {
        order: 1,
      },
      '& .close': {
        display: 'none',
      },
      '&[aria-expanded="true"]': {
        '& .open': {
          display: 'none',
        },

        '& .close': {
          display: 'block',
        },
      },
    },
  }),
);

interface IFaq {
  questionTitle: string;
  questionContent: string;
}

const QuestionRow = ({ title = '', content = '', idx = 0 }) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.accordionItem}>
      <AccordionSummary
        aria-controls={`panel-content-${idx}`}
        id={`panel-header-${idx}`}
        className={classes.summary}
        expandIcon={
          <div className="accordion-icons">
            <AddIcon className="open" />
            <RemoveIcon className="close" />
          </div>
        }
      >
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          className="markdown-holder text-center"
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export const FaqPageTemplate = ({
  title,
  content,
  subTitle,
  subContent,
  questionsList,
}: {
  title: string;
  subTitle: string;
  subContent: any;
  content: any;
  questionsList: IFaq[];
}) => {
  const classes = useStyles();
  return (
    <>
      <SEO title={title} />
      <OrangeBox>
        <Inner>
          <h2 className="font-u5 text-center mb-u3">{title}</h2>
          <div
            className="markdown-holder mb-u2 text-center font-u1"
            dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
          />
        </Inner>
      </OrangeBox>
      <RedBox>
        <Inner>
          <ThreeLink className="font-u1 bold text-center">
            <Link to="/faq/" className="b-grey">
              General FAQ
            </Link>
            <Link to="/adopting/faq" className="b-yellow">
              Adoption FAQ
            </Link>
            <Link to="/fostering/faq" className="b-blue">
              Fostering FAQ
            </Link>
          </ThreeLink>
        </Inner>
      </RedBox>
      <GreyBox>
        <Inner>
          {questionsList.length > 0 && (
            <div className={classes.root}>
              {questionsList.map(
                ({ questionContent, questionTitle }, index) => (
                  <QuestionRow
                    key={index}
                    idx={index}
                    content={questionContent}
                    title={questionTitle}
                  />
                ),
              )}
            </div>
          )}
        </Inner>
      </GreyBox>
      <WhiteBox>
        <Inner justifyContent="center" display="flex">
          <Ask className="font-u3" href="mailto:questions@citrusfcn.com">
            Ask a Question
          </Ask>
        </Inner>
      </WhiteBox>
    </>
  );
};

const FaqPage = ({
  data: {
    markdownRemark: {
      frontmatter: {
        title = '',
        content = '',
        subTitle = '',
        subContent = '',
        questionsList = [],
      },
    },
  },
}) => {
  return (
    <Layout>
      <FaqPageTemplate
        title={title}
        content={content}
        subTitle={subTitle}
        subContent={subContent}
        questionsList={questionsList}
      />
    </Layout>
  );
};

export default FaqPage;

export const faqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        content
        subTitle
        subContent
        questionsList {
          questionTitle
          questionContent
        }
      }
    }
  }
`;

const ThreeLink = styled.div`
  display: flex;
  justify-content: space-between;
  a.b-grey {
    background-color: ${Globals.gray};
    background-image: url('/cog-faq.png');
    &:hover {
      background-color: ${Globals.grayX};
    }
  }

  a.b-yellow {
    background-color: ${Globals.yellow};
    background-image: url('/house-faq.png');
    &:hover {
      background-color: ${Globals.yellowX};
    }
  }
  a.b-blue {
    background-color: ${Globals.blue};
    background-image: url('/hand-faq.png');
    &:hover {
      background-color: ${Globals.blueX};
    }
  }

  a {
    display: flex;
    align-items: center;
    background-position: center 2rem;
    background-size: 40%;
    background-repeat: no-repeat;
    width: 16rem;
    margin: 1rem;
    padding: 10rem 2rem 2rem;
    height: 14rem;
    justify-content: center;
    color: ${Globals.white};
    text-decoration: none;
    &[aria-current='page'] {
      box-shadow: inset 0 0 2rem rgba(0, 0, 0, 0.4);
    }
  }
`;

const Ask = styled.a`
  text-decoration: none;
  font-weight: bold;
  background-color: ${Globals.gray};
  justify-self: center;
  color: ${Globals.white};
  border-radius: 0.5rem;
  padding: 0 2rem;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    background-color: ${Globals.grayX};
  }
`;
